import React from 'react'
import backg1 from '../image/background/bluecircle.svg';
import backg2 from '../image/background/Mediamodifier-Design.svg';
import backg7 from '../image/background/svg-image-7.svg';
import backg5 from '../image/background/svg-image-5.svg';
import backg6 from '../image/background/svg-image-6.svg';
import group2_bg2 from '../image/background/Group 2.svg';
import backg4 from '../image/background/svg-image-4.svg';
import group3_bg3 from '../image/background/Group 3.svg';
import section3bg from '../image/background/svg-image-11.svg';
import group4_bg4 from '../image/background/svg-image-44.svg';
import marketplaseicon from '../image/background/svg-image-14.svg';
import marketplaseicon2 from '../image/background/svg-image-15.svg';
import marketplaseicon3 from '../image/background/svg-image-16.svg';
import marketplaseicon4 from '../image/background/svg-image-17.svg';
import interchainebg1 from '../image/background/svg-image-18.svg';
import interchainbg2 from '../image/background/svg-image-20.svg';
import the_most_trustedbg2 from '../image/background/svg-image-28.svg';
import DESHBORD from '../image/background/dashbrdsvg.svg';
import mobdashh from '../image/background/mobdashh.svg';
import powericon from '../image/background/powericon.svg';
import logo from '../image/Group 44.png';
import svg51 from '../image/background/svg-image-51.svg';
import svg52 from '../image/background/svg-image-52.svg';
import svg53 from '../image/background/svg-image-53.svg';
import svg54 from '../image/background/svg-image-54.svg';
import svg55 from '../image/background/svg-image-55.svg';
import svg56 from '../image/background/svg-image-56.svg';
import fundraianbg1 from '../image/background/svg-image-34.svg';
import svg33plueicon from '../image/background/svg-image-33.svg';
import you_buildbg2 from '../image/background/svg-image-35.svg';
import you_buildbg3 from '../image/background/svg-image-32.svg';







const Dasbord = () => {
    const handleDownload = () => {
        const pdfPath = '/Whitepaper.pdf';
      
        const link = document.createElement('a');
        link.href = pdfPath;
        link.download = '/Whitepaper.pdf';
        link.type = 'application/pdf'; // Set the correct content type
      
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    const handleDownloads = () => {
        const pdfPath = '/CUNAudit.pdf';
      
        const link = document.createElement('a');
        link.href = pdfPath;
        link.download = '/CUNAudit.pdf';
        link.type = 'application/pdf'; // Set the correct content type
      
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
      const handleauditDownloads = () => {
        const pdfPath = '/DFAudit.pdf';
      
        const link = document.createElement('a');
        link.href = pdfPath;
        link.download = '/DFAudit.pdf';
        link.type = 'application/pdf'; // Set the correct content type
      
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    return (
        <div className='main_dasbord'>
            <div className='desbord_background'>
                <div className='backg1'>
                    <img src={backg1} alt='back1' />
                </div>
                <div className='backg2'>
                    <img src={backg2} alt='back2' />
                </div>
                <div className="build_text">
                    <h1> Built on the blockchain</h1>
                    <p> Purchase CUN tokens and get amazing returns with decentralized protocol</p>

                    <div className='btn_Whitepaper'>
                        <button onClick={handleDownload}>Whitepaper <span className='rightarrow'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span></button>
                        <button onClick={() => window.open('https://bscscan.com/address/0x7d2291988b4f8d3fed0c6aefa131c39586fcb88a', '_blank')}>
                            Contract <span className='rightarrow'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
                        </button>
                        <button onClick={handleDownloads}>Internal Audit <span className='rightarrow'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span></button>
                        <button onClick={handleauditDownloads}>3rd Party Audit <span className='rightarrow'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span></button>
                    </div>
                </div>

                <div className='group2_img2'>
                    <img src={group2_bg2} alt='group2_bg2' />
                </div>

                <div className='group3_img3'>
                    <img src={group3_bg3} alt='group2_bg3' />
                </div>

                <div className='backg4'>
                    <img src={backg4} alt='back4' />
                </div>

                <div className='backg7'>
                    <img src={backg7} alt='back7' />
                </div>

                <div className='backg5'>
                    <img src={backg5} alt='back5' />
                </div>

                <div className='backg6'>
                    <img src={backg6} alt='back6' />
                </div>
            </div>

            <div className='Enter_new'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-9'>
                            <div className='community_left'>
                                <h3>Govern by the community</h3>
                                <h2>Price is govern by the community</h2>
                                <p>Token is completely governed by the community. It means, price will be changed on every transaction.On every purchase 85% of the amount has been sent to the liquidity pool and 15% to the referral program.</p>
                                <div className='explore_token'>
                                    <a 
                                    target='_blank'
                                    href='https://dashboard.cunetwork.ai/' className='learn_link'>Buy CUN <span className='rightarrow'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span></a>

                                    <a 
                                    target='_blank'
                                    href='https://dashboard.cunetwork.ai/' className='explore_tokn'>Dashboard <span className='rightarrow'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span></a>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-3'>
                            <div className='apps_growing apps_growing1'>
                                <h3>30M <span class="plue_sine">+</span></h3>
                                <p>Supply minted till the date</p>
                            </div>

                            <div className='apps_growing apps_growing2'>
                                <h3>83.2 <span class="plue_sine">+</span></h3>
                                <p>Security score of the contract</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='cosmos_hub'>
                <div className='container'>

                    <div className='cosmos_title'>
                        <h3>EXPLORE CUN PLATFORM</h3>
                        <h2>No authority of an owner</h2>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='section3_left_bg'>
                                <img className="sec3bg1" src={group2_bg2} alt='section3bg' />
                                <img className="sec3bg2" src={group3_bg3} alt='section3bg' />
                                <img className="sec3bg3" src={section3bg} alt='section3bg' />
                                <img className="sec3bg4" src={group4_bg4} alt='section3bg' />
                            </div>
                        </div>

                        <div className='col-lg-8'>
                            <div className='service_econ_text'>
                                <p>The contract is 100% decentralized and has no 
                                    owner authority of any withdrawal function 
                                    or liquidity removal from the contract</p>
                            </div>

                            <div className='marketplase'>
                                <div className='security_maerkrt4'>
                                    <img src={marketplaseicon} alt='marketplaseicon' />
                                    <h4>Referrals</h4>
                                    <p>User can get rewards using reffer and earn. 
                                    Referral rewards are extended upto 10 levels.
                                    Each level can be unlocked after self activation.
                                    Rewards are sent upto 10 levels</p>
                                </div>

                                <div className='security_maerkrt4'>
                                    <img src={marketplaseicon2} alt='marketplaseicon' />
                                    <h4>Optimum Security</h4>
                                    <p>CUN Works on smart contract, which makes it safer
                                        because of its decentralization. Funds sent into CUN
                                        contract are 100% and secure on the blockchain.
                                    </p>
                                </div>

                                <div className='security_maerkrt4'>
                                    <img src={marketplaseicon3} alt='marketplaseicon' />
                                    <h4>No Router</h4>
                                    <p>As CUN is not going to be listed on any exchange 
                                        platform, the price is governed and determined inside 
                                        the contract. The contract does not use any external Router
                                        for token selling & purchase.</p>
                                </div>

                                <div className='security_maerkrt4'>
                                    <img src={marketplaseicon4} alt='marketplaseicon' />
                                    <h4>Custody</h4>
                                    <p>CUN Platform allows user to use non-custodial wallets for 
                                        all the transactions. Custody is never delegated, even if it's the 
                                        user or an admin of the contract.
                                    </p>
                                </div>

                                <div className='security_maerkrt4'>
                                    <img src={marketplaseicon} alt='marketplaseicon' />
                                    <h4>Liquidity pool</h4>
                                    <p>85% Of the amount directly goes into a liquidity pool upon purchase.
                                    10% of the amount is sent to the liquidity pool on every sell, which keeps the pool well balanced
                                    </p>
                                </div>

                                <div className='security_maerkrt4'>
                                    <img src={marketplaseicon2} alt='marketplaseicon' />
                                    <h4>Unlock levels</h4>
                                    <p>You can unlock levels upto 10 after self activation. Your direct referrals
                                        can also help you to unlock the level by purchasing CUN tokens with your referral link.
                                        Share your referral link & earn
                                    </p>
                                </div>
                            </div>

                            <div className='cosmos_hub_link'>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='desbord_img'>
                <div className='desshbord_image'>
                    <div className='Powerful'>
                        <h5><span><img className="powerfulicon" src={powericon} alt='powericon' /></span>Sophisticated Dashboard</h5>
                        <h1>User friendly dashboard</h1>

                        <p>Keep the track of your purchases, withdrawal & sell with versatile 
                            CUN web3 dashboard. Connect wallet and explore seamlessly</p>
                        {/* <a className="lansh_desh" href='#'> <span className='rightarrow'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span></a> */}
                        <a 
                        target='_blank'
                        href='https://dashboard.cunetwork.ai/' className='learn_link'>Launch Dashboard<span className='rightarrow'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span></a>
                    </div>
                    <img className="deshbordFullscren" src={DESHBORD} alt='DESHBORD' />
                    <img className="mobdashh" src={mobdashh} alt='mobdashh' />
                </div>
            </div>

            <div className='you_build'>

                <img className='you_buildbg1' src={group3_bg3} alt='you_buildbg1' />
                <img className='you_buildbg2' src={you_buildbg2} alt='you_buildbg2' />
                <img className='you_buildbg3' src={you_buildbg3} alt='you_buildbg3' />
                <div className='container'>
                    <div className='you_build_main'>
                        <div className='you_build_title'>
                            <h1>CUN ultimate guide</h1>
                            <p>Purchasing, withdrawal, selling of the CUN token is the easiest process. Below is the guide of how you can buy, sell & withdraw your tokens.
                                Purchasing at its easiest.
                            </p>
                        </div>

                        <div className='finance_app_main'>

                            <div className='finance_app'>
                                <h5><img src={svg33plueicon} alt='plueIcon' /></h5>

                                <h2>How to approve USDT & buy CUN tokens</h2>
                                <p>After connecting your wallet to BSC network, enter amount in Approve USDT section and click on approve to get the approval from contract.
                                    Enter amount of USDT in Purchase CUN token & click on buy.
                                </p>
                            </div>

                            <div className='finance_app'>
                                <h5><img src={svg33plueicon} alt='plueIcon' /></h5>

                                <h2>How to sell CUN tokens easily on dashboard</h2>
                                <p>Make sure you have available tokens & valid selling limit before selling the tokens. After Entering the amount in sell tokens field click on click to sell button to sell out the tokens. USDT will be sent to your wallet </p>
                            </div>

                            <div className='finance_app'>
                                <h5><img src={svg33plueicon} alt='plueIcon' /></h5>

                                <h2>How to withdraw rewards from dashboard</h2>
                                <p>Make sure you have enough rewards & reward limit before going to withdraw the rewards. Enter the amount of USDT you want to withdraw from available rewards & click on click to withdraw button.</p>
                            </div>

                            <div className='fundraian'>
                                <h5>Join Telegram community</h5>
                                <div className='funimg'>
                                    <img className="fundraianbg2" src={marketplaseicon} alt='fundraianbg2' />
                                    <img className="fundraianbg3" src={marketplaseicon} alt='fundraianbg3' />
                                </div>
                                <h3> Become a precious holder</h3>

                                <a 
                                target='_blank'
                                href='https://t.me/cunetwork'>Join now <span className='rightarrow'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span></a>
                            </div>

                            <div className='fundraian fundraian2'>
                                <h5>HODL Always</h5>
                                <div className='funimg'>
                                    <img className="fundraianbg1" src={fundraianbg1} alt='fundraianbg1' />
                                </div>
                                <h3> HODL is better than dumping</h3>

                                <a href='https://dashboard.cunetwork.ai/'>Buy now <span className='rightarrow'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span></a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className='securety_buy'>
                <div className='container'>
                    <h2>How to earn with CUN</h2>

                    <p>Earning crypto with CUN is best & secure way anyone could come across. Watch how you can use the CUN platform</p>

                    <div className='staking_link'>
                        <a 
                        target='_blank'
                        href='https://www.youtube.com/' className='learn_link'>Watch <span className='rightarrow'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span></a>

                        <a href='https://www.youtube.com/' className='explore_tokn'>Explore <span className='rightarrow'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span></a>
                    </div>
                </div>
            </div>

            <div className='interchaine'>
                <img className="interchainebg1" src={interchainebg1} alt='interchainebg1' />

                <div className='container'>
                    <div className='interchain'>
                        <h2>Read the tokenomics</h2>

                        <a href='#'>Read CUN Blogs here →</a>
                    </div>

                    <div className='interchain_accounts'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <div className='interchain_acc_card_left'>
                                    <h3>ON EVERY PURCHASE</h3>

                                    <img className='interchainbg1' src={marketplaseicon4} alt='interchainbg1' />
                                    <img className='interchainbg2' src={interchainbg2} alt="interchainbg2" />

                                    <h1>85% USDT has been sent into the Liquidity pool</h1>

                                    <p>No LP router needed</p>
                                </div>
                            </div>

                            <div className='col-lg-7'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='DECENTRALIZED_EXCHANGE_right'>
                                            <h3>WE BURN CUN ON EVERY SELL</h3>

                                            <div className='dexent_ex_ribgimg'>
                                                <img className='interchainbg3' src={marketplaseicon} alt='interchainbg3' />
                                            </div>
                                            <h1>100% Tokens burned instantly</h1>

                                            <p>Balance price</p>
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>
                                        <div className='LIQUIDITY_POOLS'>
                                            <h3>MINTING CUN</h3>

                                            <h1>60% Minted instantly
                                                on purchase
                                            </h1>

                                            <p>50%User-10%Admin</p>
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>
                                        <div className='LIQUIDITY_POOLS'>
                                            <h3>Upto Level-10</h3>

                                            <h1>Rewards upto level-10 on purchase</h1>

                                            <p className='LIQUIDITY_POOLS_p'>Instant rewards</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div className='the_most_trusted'>

                <img className="the_most_trustedbg1" src={group2_bg2} alt='the_most_trustedbg1' />
                <img className="the_most_trustedbg2" src={the_most_trustedbg2} alt='the_most_trustedbg2' />
                <img className="the_most_trustedbg3" src={group4_bg4} alt='the_most_trustedbg3' />
                <div className='container'>
                    <div className='SDK_TECHNOLOGY'>
                        <h4>Why to purchase CUN</h4>
                        <h1>The most trusted crypto platform</h1>
                    </div>

                    <div className='cosmos_sdk_trustd'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='cosmos_content '>
                                        <p>
                                           Safety is the uttermost thing in crypto, which can be provided on CUN with other benifits as well. Good Returns & best value you wouldn't want to miss. <br></br>
                                            <br></br>
                                           No vulnerability by any means, which is something that you do not get everywhere in web3 & cryptocurrency.
                                           Buy now & Reach moon..</p>
                                    </div>
                                </div>

                                <div className='col-lg-6'>
                                    <div className='cosmos_content cosmos_content2'>
                                        <h4>PROOF-STAKE-AUHTORITY</h4>

                                        <div className='lower_carbon'>
                                            <h1>100%</h1>
                                            <h3>Ultimate security</h3>
                                        </div>

                                        <h6>Safer purchase & withdrawals</h6>
                                    </div>
                                </div>

                                <div className='col-lg-6'>
                                    <div className='cosmos_content'>
                                        <h4>LOW GAS FEES</h4>

                                        <div className='lower_carbon'>
                                            <h1>$0.01</h1>
                                        </div>

                                        <h6>Enjoy the lowest fees – almost zero.</h6>
                                    </div>
                                </div>

                                <div className='col-lg-6'>
                                    <div className='cosmos_content cosmos_content2'>
                                        <h4>INSTANT REWARDS</h4>

                                        <div className='lower_carbon'>
                                            <h1>07</h1>
                                            <h3>Sec</h3>
                                        </div>

                                        <h6>Rewards sent instantly to wallet.</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='foter'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6'>
                            <div className='foter_option' >
                                <h4>Products & Solutions</h4>
                                <a href='#'>Cunetwork</a>
                                <a href='#'>CometBFT</a>
                                <a href='#'>IBC Protocol</a>
                                <a href='#'>CosmWasm</a>
                                <a href='#'>Neutron</a>
                                <a href='#'>Interchain Security</a>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-6'>
                            <div className='foter_option' >
                                <h4>Developers</h4>
                                <a href='#'>Tutorials</a>
                                <a href='#'>Developer Academy</a>
                                <a href='#'>Builders Program</a>
                                <a href='#'>FAQ</a>
                                <a href='#'>IBC Protocol Docs</a>
                                <a href='#'>Cosmos SDK Docs</a>
                                <a href='#'>CometBFT Docs</a>
                                <a href='#'>CosmWasm Docs</a>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-6'>
                            <div className='foter_option' >
                                <h4>Ecosystem</h4>
                                <a href='#'>Wallets</a>
                                <a href='#'>Validators</a>
                                <a href='#'>Case Studies</a>
                                <a href='#'>Whitepaper</a>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-6'>
                            <div className='foter_option' >
                                <h4>Resources</h4>
                                <a href='#'>About</a>
                                <a href='#'>Contact</a>
                                <a href='#'>Press Kit</a>
                                <a href='#'>Blog</a>
                                <a href='#'>Discord</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='last_footer'>
                <div className='container'>
                    <div className='privacy_footer'>
                        <div className='tradmark_right'>
                            <a href='#'><img src={logo} alt='logo' /> </a>
                            <a href='/Privacy_policy'> <span>|</span> Privacy</a>
                            <a href='/Terms_conditions'> <span>|</span> Terms</a>
                        </div>

                        <div className='tradmark_left'>
                            <a href='#'><img src={svg51} alt='svg51' /> </a>
                            <a href='#'><img src={svg52} alt='svg52' /> </a>
                            <a href='#'><img src={svg53} alt='svg53' /> </a>
                            <a target='_blank'href='https://t.me/cunetwork'><img src={svg54} alt='svg54' /> </a>
                            <a href='#'><img src={svg55} alt='svg55' /> </a>
                            <a href='#'><img src={svg56} alt='svg56' /> </a>
                        </div>
                    </div>

                    <div className='last_footer_text'>
                        <p>© All Rights Reserved. cunetwork.ai</p>

                        <p>Trading cryptocurrencies carries a high level of risk, and may not be suitable for all investors. Before deciding to trade cryptocurrency, you should carefully consider your investment objectives, level of experience, and risk appetite. The possibility exists that you could sustain a loss of some or all of your initial investment and therefore you should not invest money that you cannot afford to lose. You should be aware of all the risks associated with cryptocurrency trading, and seek advice from an independent financial advisor. Any opinions, news, research, analyses, prices, or other information contained on this website is provided as general market commentary, and does not constitute investment advice. The CUNetwork will not accept liability for any loss or damage, including without limitation to, any loss of profit, which may arise directly or indirectly from use of or reliance on such information. All opinions expressed on this site are owned by the respective writer and should never be considered as advice in any form. The CUNetwork makes no representation or warranties as to the accuracy and or timelines of the information contained herein. A qualified professional should be consulted before making any financial decisions.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dasbord
