import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Navbar from './component/Navbar';
import Dasbord from './component/Dasbord';
import {BrowserRouter as Router} from 'react-router-dom';

// Create a root for the entire application
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <App />
  </Router>
);

// // Use a different name for Navbar component
// const navbarRoot = ReactDOM.createRoot(document.getElementById('navbar'));

// navbarRoot.render(
//   <React.StrictMode>
//     <Navbar />
//   </React.StrictMode>
// );

// const DasbordRoot = ReactDOM.createRoot(document.getElementById('dasbord'));

// DasbordRoot.render(
//   <React.StrictMode>
//     <Dasbord />
//   </React.StrictMode>
// );
