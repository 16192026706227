import React from 'react'
import  "./style.css"
import group4_bg4 from '../image/background/svg-image-44.svg';

const Privacy_policy = () => {
  return (
    <div>
    <div className='disclaimer'>

<img className="group4_bg4" src={group4_bg4} alt='group4_bg4'/>
{/* <img className="group3_bg3" src={group3_bg3} alt='group3_bg3'/> */}

<div className='container'>
  <div className='disclaimer_conatnt2'>
      <h2>Privacy Policy for CUN</h2>
      <p className='disclaimer_conatnt2_p'>Thank you for using CUN.!!! This Privacy Policy outlines how we collect, 
        use, disclose, and safeguard your personal information. By using cunetwork.ai,
         you agree to the terms outlined in this policy.</p>

        <h3>1.Information We Collect</h3>
        <p>a. <b>Personal Information:</b> We do not collect personal information such as your name, email address, and wallet address when you use CUN</p>
        <p>b. <b>Transaction Data:</b> We do not collect information related to your transactions, including transaction history and details but stored on the blockchain with transaction hash.</p>
        <p>c. <b>Device Information:</b> We do not collect information about the device you use to access cunetwork.ai, including device type, operating system, and IP address.</p>

        <h3>2.Do We Use Your Information:</h3>
        <p>a. <b>Transaction Processing:</b> 
We do not use your personal information to process transactions and provide you with the services offered by CUN</p>
        <p>b. <b> Improving Services: </b>
We analyze data to enhance and improve the functionality and performance of [Your Crypto Token].</p>
        <p>c. <b>Communications:</b> We may use your email address to send important updates, announcements, and information related to [Your Crypto Token].</p>
 
        <h3>3.Information Sharing</h3>
        <p> <b>a. Third Parties:</b>We may share your information with trusted third parties for the purpose of providing and improving our services. These third parties are obligated to maintain the confidentiality and security of your information.</p>
       <p> <b>b. Legal Compliance:</b>We may disclose your information if required by law or in response to legal requests.
</p>
        <h3>4.Security</h3>
        <p>We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.</p>
 
        <h3>5. Your Choices</h3>
        <p><b>a. Opt-Out:</b>You can opt-out of receiving non-essential communications from us by following the instructions in the communication.</p>

        <p><b>b. Access and Update:</b>You can access and update your personal information by contacting us at on community channel</p>
 
        <h3>6. Changes to this Privacy Policy</h3>
        <p>We may update this Privacy Policy to reflect changes in our practices. Please review this policy periodically for any updates.</p>
  
        <h3>7. Contact Us</h3>
        <p>If you have any questions or concerns about this Privacy Policy, let us know..</p>
  
  </div>
</div>
</div>
    </div>
  )
}

export default Privacy_policy
