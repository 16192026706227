import React from 'react'
import logo from '../image/Group 44.png'
import { Link } from 'react-router-dom';
const Navbar = () => {

    const myFunction = () => {
        var x = document.getElementById("myTopnav");
        if (x.className === "topnav") {
            x.className += " responsive";
        } else {
            x.className = "topnav";
        }
    };

    return (
        <div className='Navbar_Main'>
            <div className='container'>
                <div class="topnav" id="myTopnav">
                    {/* <div className='logos'>
                    <h4> <img src={logo} alt="Logo" /> </h4>
                    </div> */}

                    <div className='menus'>
                        <Link to="/" >  <h4> <img src={logo} alt="Logo" /> </h4></Link>
                        {/* <a target='_blank'href='https://dashboard.cunetwork.ai/'> Dashboard</a> */}
                        {/* <Link to="/Terms_conditions">Terms & conditions</Link>
                    <Link to="/Privacy_policy">Privacy policy</Link>
                    <Link to="/Disclaimer">Disclaimer</Link> */}
                        {/* <Link to="/Contact">Contact</Link> */}
                        {/* <div class="dropdown">
                            <button class="dropbtn">Dropdown
                                <i class="fa fa-caret-down"></i>
                            </button>
                            <div class="dropdown-content">
                                <a href="#">Link 1</a>
                                <a href="#">Link 2</a>
                                <a href="#">Link 3</a>
                            </div>
                        </div> */}
                        {/* <a href="#about">About</a> */}
                        {/* <a href="#home" className="icon" style={{ fontSize: '25px' }} onClick={myFunction}>&#9776;</a> */}
                        <div class="btn_nav">
                        <button onClick={() => window.open('https://dashboard.cunetwork.ai', '_blank')}>
                            Dashboard <span className='rightarrow'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></span>
                        </button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Navbar
