import React from 'react'
import  "./style.css"
import group4_bg4 from '../image/background/svg-image-44.svg';
// import group3_bg3 from '../image/background/Group 3.svg';

const Disclaimer = () => {
  return (
    <div>
      <div className='disclaimer'>

        <img className="group4_bg4" src={group4_bg4} alt='group4_bg4'/>
        {/* <img className="group3_bg3" src={group3_bg3} alt='group3_bg3'/> */}

        <div className='container'>
          <div className='disclaimer_conatnt'>

            <h1>Disclaimer</h1>
            <p>Trading cryptocurrencies carries a high level of risk,
              and may not be suitable for all investors. Before deciding
              to trade cryptocurrency, you should carefully consider your
              investment objectives, level of experience, and risk appetite.
              The possibility exists that you could sustain a loss of some or
              all of your initial investment and therefore you should not invest
              money that you cannot afford to lose. You should be aware of all the
              risks associated with cryptocurrency trading, and seek advice from
              an independent financial advisor. Any opinions, news, research,
              analyses, prices, or other information contained on this website
              is provided as general market commentary, and does not constitute
              investment advice. The CUNetwork will not accept liability for any
              loss or damage, including without limitation to, any loss of profit,
              which may arise directly or indirectly from use of or reliance on
              such information. All opinions expressed on this site are owned by
              the respective writer and should never be considered as advice in
              any form. The CUNetwork makes no representation or warranties as to
              the accuracy and or timelines of the information contained herein.
              A qualified professional should be consulted before making any financial
              decisions.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Disclaimer
