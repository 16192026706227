// import logo from './logo.svg';
import React from "react";
import './App.css';
import Navbar from "./component/Navbar"
import Deshbord from "./component/Dasbord"
import {Route, Link, Routes} from 'react-router-dom';
import Disclaimer from "./page/Disclaimer";
import Terms_conditions from "./page/Terms_conditions"
import Privacy_policy from "./page/Privacy_policy"


function App() {
  return (
    <React.Fragment>
      <Navbar />
      <Routes>
      <Route path="/" element={<Deshbord />} />
      <Route path="/Disclaimer" element={<Disclaimer />} />
      <Route path="/Terms_conditions" element={<Terms_conditions/>} />
      <Route path="/Privacy_policy" element={<Privacy_policy/>} />
      
      </Routes>
    </React.Fragment>
  );
}

export default App;
